import { CountryCode } from "@smartrr/shared/shopifyGraphQL/api";
export const initialFormValues = {
  shopifyCustomerId: "",
  stripeCustomerId: "",
  email: "",
  firstName: "",
  lastName: "",
  address1: "",
  address2: "",
  country: CountryCode.Us,
  city: "",
  phone: "",
  province: "",
  zip: "",
};

export const languageCountryMap = {
  en: "US",
  fr: "FR",
  es: "ES",
};

export enum SelectedPaymentMethodsEnum {
  shopify = "shopify",
  stripe = "stripe",
  paypal = "paypal",
}

export const ADD_PRODUCT_MODAL_TITLE = "Add products to subscription";
export const CONFIRMATION_MODAL_TITLE = "Subscription created successfully";
export const PAYMENT_INFORMATION_SECTION_TITLE = "Payment information";
export const IMPORT_CUSTOMER_MODAL_TITLE = "Import customer from Shopify";
export const CUSTOM_BILLING_POLICY_BUTTON_LABEL_ROOT = "custom billing policy";
export const PRODUCTS_TITLE = "Line items";
export const CREATE_SUBSCRIPTION_TITLE = "Creating a new subscription";
export const MAX_CUSTOMER_TO_DISPLAY = 7;
export const BACK_TO_SUBSCRIPTIONS_BUTTON_TITLE = "Back to subscriptions";
export const NO_CUSTOMER_SELECTED_SUBTITLE = "Select a customer to get started.";
export const NO_CUSTOMER_ADDRESS_SUBTITLE = "Add customer's shipping address.";
export const MAX_IMAGES_TO_DISPLAY = 5;
