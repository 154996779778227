import { Box } from "@smartrr/shared/components/primitives";
import styled from "styled-components";

export const BankCard = styled(Box)`
  > span {
    &:last-child {
      color: rgb(109, 113, 117);
    }
  }
`;
