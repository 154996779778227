import { CountryCode } from "@smartrr/shared/shopifyGraphQL/api";
export const initialFormValues = {
  shopifyCustomerId: "",
  stripeCustomerId: "",
  email: "",
  firstName: "",
  lastName: "",
  address1: "",
  address2: "",
  country: CountryCode.Us,
  city: "",
  phone: "",
  province: "",
  zip: "",
};

export const ADD_PRODUCT_MODAL_TITLE = "Add products to subscription";
export const CONFIRMATION_MODAL_TITLE = "Subscription created successfully";
export const PAYMENT_INFORMATION_SECTION_TITLE = "Payment information";
export const IMPORT_CUSTOMER_MODAL_TITLE = "Import customer from Shopify";
export const CUSTOM_BILLING_POLICY_BUTTON_LABEL_ROOT = "custom billing policy";
export const PRODUCTS_TITLE = "Line items";
export const CREATE_SUBSCRIPTION_TITLE = "Create new subscription";
