import { Text } from "@shopify/polaris";
import { Box } from "@smartrr/shared/components/primitives";
import { DEFAULT_VARIANT_TITLE } from "@smartrr/shared/constants";
import React, { memo } from "react";

export interface IProductName {
  purchasableName: string;
  purchasableVariantName?: string;
}

export const ProductName = memo(({ purchasableName, purchasableVariantName }: IProductName) => {
  return (
    <Box vertical>
      <span data-testid="product-name">{purchasableName}</span>
      {!!purchasableVariantName && purchasableVariantName !== DEFAULT_VARIANT_TITLE && (
        <Text variant="bodyMd" as="span" color="subdued">
          {purchasableVariantName}
        </Text>
      )}
    </Box>
  );
});
